<template>
  <v-layout class="flex-column white max-width" style="min-height:100%;">
    <!-- <div  class="white"> -->
    <div class="flex-y-center flex-justify-between px-2" style="border-bottom:1px solid #e8e8e8 ">
      <!-- userCategory -->
      <v-tabs v-model="tab" @change="tabChange">
        <v-tab :href="`#1`">内部用户</v-tab>
        <v-tab :href="`#2`">外部用户</v-tab>
      </v-tabs>

    </div>
    <!-- <div class=" flex-1">  max-height-->
    <div class=" flex-1 flex-justify-between max-width " style="min-height:100%;">

      <div class="  flex-1 max-width">
        <div class=" ma-4 my-2 flex-justify-between">
          <div class=" flex-align-start">
            <!-- <v-btn v-if="tab==1" dark small @click="activation.show=true" color="#00bea9" depressed min-width='30' style="height:32px;" class="mr-2">
                          批量激活
                        </v-btn> -->
            <!-- drawer.data={selecteddeparts:[],selectedroles:[]} -->
            <v-btn dark small color="#00bea9" depressed min-width="32" class="mr-2" height="30" style="padding:0 8px" @click="chooseAdmin=false,errorPhone=false,errorEmail=false,drawer.data={},drawer.selecteddeparts=[],drawer.status='1',drawer.selectedroles=[],drawer.departIds=[],drawer.show=true">
              <v-icon size="18">mdi-plus</v-icon>新增{{ tab=='1'?'内部':'外部' }}用户
            </v-btn>
            <exportBtn :url="$api.userExportList" class="mr-2" :parmas="{...search.data,userCategory:tab}" />
            <importBtn :template-url="$api.userExportUserTemplate" :import-url="$api.userImportUserTemplate" :parmas="{isOuter:tab=='1'?0:1}" @importOk="initList" />

            <!-- <v-btn dark small @click="drawer.data={},drawer.show=true" color="#00bea9" depressed min-width='30' style="height:32px;" class="mr-2">
                          新增用户
                        </v-btn> -->
            <!-- <v-btn dark small color="#00bea9" depressed min-width='30' style="height:32px;" class="mr-2">
                          导入
                        </v-btn> -->
          </div>
          <div class=" flex-y-center flex-wrap">
            <div class="flex-y-center mr-1 mb-1" style="width:120px;">
              <form-item
                v-model="search.data.username"
                class="mr-0"
                placeholder="用户账号"
                type="text"
                allow-close
                @onEnter="initList()"
              />
            </div>
            <div class="flex-y-center mr-1 mb-1" style="width:120px;">
              <form-item
                v-model="search.data.realname"
                class="mr-0"
                placeholder="姓名"
                type="text"
                allow-close
                @onEnter="initList()"
              />
            </div>
            <div class="flex-y-center mr-1 mb-1" style="width:120px;">
              <form-item
                v-model="search.data.sex"
                class=" flex-0 flex-shrink-0"
                :options="[{text:'男',value:1},{text:'女',value:2}]"
                placeholder="性别"
                style="width: 120px"
                dense
                type="select"
                allow-close
                @select="(e)=>{search.data.sex = e.val,initList()}"
                @cleanVal="(e)=>{search.data.sex = e.val,initList()}"
              />
            </div>
            <div class="flex-y-center mr-1 mb-1" style="width:120px;">
              <form-item
                v-model="search.data.email"
                class="mr-0"
                placeholder="邮箱"
                type="text"
                allow-close
                @onEnter="initList()"
              />
            </div>
            <div class="flex-y-center mr-1 mb-1" style="width:120px;">
              <form-item
                v-model="search.data.phone"
                class="mr-0"
                placeholder="联系电话"
                type="text"
                allow-close
                @onEnter="initList()"
              />
            </div>
            <template v-if="tab==1">
              <div class="flex-y-center mr-1 mb-1" style="width:120px;">
                <form-item
                  v-model="search.data.roleName"
                  class="mr-0"
                  placeholder="组织角色"
                  type="text"
                  allow-close
                  @onEnter="initList()"
                />
              </div>
              <div class="flex-y-center mr-2 mb-1" style="width:120px;">
                <!-- <form-item
                  v-model="search.data.departIds"
                  class=" flex-0 flex-shrink-0"
                  :options="[]"
                  placeholder="部门"
                  style="width: 120px"
                  dense
                  type="select"
                /> -->
                <v-menu
                  v-model="showMenuDepart"
                  :close-on-content-click="false"
                  bottom
                  max-height="300"
                  offset-overflow
                  transition="slide-y-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <div class=" default-input flex-y-center flex-justify-between py-1" color="primary" style="height:30px;background:#fdfdfd;position: relative;" v-on="on">
                      <!-- {{ search.data.departIds }}== -->
                      <span v-if="search.data.departIds" :title="search.departName" class=" flex-1  font-overflow">
                        {{ search.departName }}
                      </span>
                      <span v-else class=" grey--text text--lighten-1">部门</span>
                      <div class=" flex-0 flex-y-center">
                        <v-icon v-if="search.data.departIds" class="mr-1" size="16" @click.stop="search.data.departIds='',search.departName='',$forceUpdate(),initList()">mdi-close</v-icon>
                        <v-icon size="20">mdi-chevron-down</v-icon>
                      </div>
                    </div>
                  </template>
                  <v-card class=" elevation-0">
                    <v-treeview
                      :items="trees"
                      item-children="children"
                      item-text="folderName"
                      transition
                    >
                      <template v-slot:label="{ item }">
                        <div
                          class="flex-y-center"
                          style="min-height: 30px"
                          @click.stop="searchDepart(item)"
                        >
                          <div
                            class="max-width max-height flex-y-center user-select"
                            style="min-height: 30px"
                            :title="item.departName"
                          >
                            <!-- information.data.statusFlag=='1' -->
                            <!-- :class="(search.data.departIds==item.id)?'light-blue lighten-5':''" -->
                            <a
                              class="grey--text text--darken-3 pl-1 max-width max-height flex-y-center"
                              style="min-height: 30px"
                            >{{ item.departName }}
                            </a>
                          </div>
                        </div>
                      </template>
                    </v-treeview>
                  </v-card>
                </v-menu>
              </div>
            </template>
            <div v-else class="flex-y-center mr-1 mb-1" style="width:120px;">
              <form-item
                v-model="search.data.partnerName"
                class="mr-0"
                placeholder="组织单位"
                type="text"
                allow-close
                @onEnter="initList()"
              />
            </div>
            <div class="flex-y-center mr-2 mb-1" style="width:120px;">
              <form-item
                v-model="search.data.status"
                class=" flex-0 flex-shrink-0"
                :options="tab==1?statusOptions:statusExternalOptions"
                placeholder="状态"
                style="width: 120px"
                dense
                type="select"
                allow-close
                @select="(e)=>{search.data.status = e.val,initList()}"
                @cleanVal="(e)=>{search.data.status = e.val,initList()}"
              />
            </div>
            <v-btn dark small :loading="listLoading" color="primary" class="mr-1 mb-1" depressed min-width="34" height="30" style="padding:0;" @click="initList">
              <v-icon size="18">mdi-magnify</v-icon>
            </v-btn>
            <v-btn dark small color="grey lighten-1" :loading="listLoading" depressed class=" mb-1" min-width="34" height="30" style="padding:0;" @click="search.data={},initList()">
              <v-icon size="18">mdi-restore</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="mx-2 mb-2 red--text body-1">（注：“锁定”状态的用户，可在前台系统的登录界面，通过“忘记密码”功能，恢复状态）</div>
        <v-data-table
          :headers="headers"
          :items="list"
          :items-per-page="search.pageSize"
          class="pb-5 px-4 max-width overflow-auto header-grey"
          hide-default-footer
          item-key="id"
          :loading="listLoading"
          disable-sort
          no-data-text="暂无查询数据"
        >

          <!-- <template v-slot:header.organization>
                        {{tab==1?'组织角色':'组织单位'}}
                    </template> -->
          <!-- <template v-slot:header.orgCodeTxt> -->
          <template v-slot:header.roleName>
            {{ tab==1?'组织角色':'组织单位' }}
          </template>
          <!-- <template v-slot:item.orgCodeTxt="{item}"> -->
          <template v-slot:item.rowIndex="{index}">
            {{ (index+1)+(search.pageSize*(search.pageNo-1)) }}
          </template>
          <template v-slot:item.username="{item}">
            <div class=" flex-y-center">
              {{ item.username }}
              <!-- <v-chip
                v-if="item.managerFlag=='1'"
                class="ml-1 px-1 caption"
                color="success"
                label
                x-small
                text-color="white"
              >
                主
              </v-chip> -->
            </div>
          </template>
          <template v-slot:item.roleName="{item}">
            <template v-if="tab==1">
              {{ item.roleName }}
            </template>
            <template v-else>
              {{ item.partnerName }}
            </template>
          </template>

          <template v-slot:item.sex="{item}">
            {{ item.sex==1?'男':(item.sex==2?'女':'-') }}
          </template>
          <template v-slot:item.expirationTime="{item}">
            <span :class="{'red--text':item.expirationTime && (new Date().getTime()>(new Date(item.expirationTime).getTime() + 86400000))}">
              {{ item.expirationTime }}
            </span>
          </template>
          <template v-slot:item.status="{item}">
            <!-- 优先级 删除、禁用、锁定、过期 -->
            <!-- expirationTime判断是否过期 -->
            <span v-if="item.delFlag ==1 " class="error--text">已删除</span>
            <!-- <span v-else-if="item.lockFlag == 1" class="error--text">锁定</span>
            <span v-else :class="item.status==2?'error--text':''">{{ item.status==1?'可用':(item.status==2?'禁用':'-') }}</span> -->
            <span v-else-if="item.status == 2" class="error--text">禁用</span>
            <span v-else-if="item.lockFlag == 1" class="error--text">锁定</span>
            <span v-else-if="item.expirationTime && (new Date().getTime()>(new Date(item.expirationTime).getTime() + 86400000))" class="error--text">
              过期
            </span>
            <span v-else>{{ item.status==1?'可用':'-' }}</span>
          </template>
          <template v-slot:item.active="{item}">
            <div class=" flex-y-center">
              <!-- mdi-lock-open-outline  mdi-key-outline mdi-account-edit-outline -->
              <!-- <v-icon :disabled="item.delFlag==1 || item.status==2" color="primary" size="18" title="编辑密码" class=" mr-1" @click="item.status==2?'':(passwordDialog.data.userId=item.id,passwordDialog.show=true)">mdi-shield-key-outline</v-icon> -->

              <!-- <a class=" mr-2" @click="showEdit(item)">编辑</a>
                        <a class=" mr-2" @click="showDel(item.id)">删除</a> -->
              <!-- <a class=" mr-2" v-if="item.status==1" @click="userFrozenBatch(item.id,'2')">冻结</a> -->
              <!-- <a class=" mr-2" v-else-if="item.status==2" @click="userFrozenBatch(item.id,'1')">恢复</a> -->

              <template v-if="!(tab==1&&(item.username == 'system'||item.username == 'administrator'))">
                <v-icon :disabled="item.delFlag==1 || item.status==2" color="primary" size="18" title="编辑" class=" mr-1" @click="item.status==2?'':showEdit(item)">mdi-square-edit-outline</v-icon>
                <v-icon v-if="item.delFlag==1" color="primary" size="18" title="恢复" class=" mr-1" @click="userRecovery(item.id)">mdi-replay</v-icon>
                <template v-else>
                  <!-- 判断是否过期 过期的不能禁用 -->
                  <v-icon v-if="item.status==1" :disabled="(item.expirationTime && (new Date().getTime()>(new Date(item.expirationTime).getTime() + 86400000)))" color="error" size="18" title="禁用" class=" mr-1" @click="userFrozenBatch(item.id,'2')">mdi-cancel</v-icon>
                  <v-icon v-else-if="item.status==2" color="primary" size="18" title="恢复" class=" mr-1" @click="userFrozenBatch(item.id,'1')">mdi-replay</v-icon>
                </template>
                <v-icon :disabled="item.delFlag==1 || item.status==2" color="error" size="20" title="删除" class=" mr-1" @click="item.status==2?'':showDel(item.id)">mdi-delete-outline</v-icon>
              </template>
              <!-- [''].includes(item.username) -->
              <!-- :disabled="item.delFlag==1 || item.status==2 || (item.expirationTime && (new Date().getTime()>(new Date(item.expirationTime).getTime() + 86400000)))" -->
              <v-icon v-if="userMsg.username == 'administrator' && (item.username !== 'administrator')" color="primary" size="18" class=" mr-1" title="重置密码" @click="resetUserPassword(item.id)">mdi-shield-refresh-outline</v-icon>
              <v-icon color="primary" size="20" title="关联项目" @click="projectDialog.search.pageNo=1,projectDialog.total=0,projectDialog.list=[],projectDialog.show=true,projectDialog.userId=item.id,userGetRelatedProjects()">mdi-view-dashboard-outline</v-icon>
            </div>
          </template>
        </v-data-table>
        <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end">
          <pagination v-model="search.pageNo" :size="search.pageSize" :total="total" @input="getUserList" @changePageSize="changePageSize" />
        </div>

      </div>
    </div>
    <!-- </div> -->

    <!-- 批量激活statr (没用到) -->
    <v-dialog v-model="activation.show" width="800">
      <v-card>
        <v-card-title class="font-weight-bold">批量激活</v-card-title>
        <v-divider />
        <v-card-text class=" py-5">
          <div class="flex-1 flex-column overflow-auto px-3">

            <v-data-table
              :headers="activation.headers"
              :items="activation.list"
              :items-per-page="activation.search.pageSize"
              class="pb-5 max-width "
              hide-default-footer
              item-key="id"
              no-data-text="暂无查询数据"
            >
              <template v-slot:item.rowIndex="{index}">
                {{ (index+1)+(activation.search.pageSize*(activation.search.pageNo-1)) }}
              </template>
              <template v-slot:item.realname="{item}">
                <input v-model="item.realname" class="flex-1 default-input py-1 subtitle-1 white" placeholder="用户名" type="text">
              </template>
              <template v-slot:item.organization="{item}">
                <v-menu
                  :close-on-content-click="false"
                  bottom
                  max-height="300"
                  offset-overflow
                  transition="slide-y-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <div class="default-input white" color="primary" v-on="on">
                      <input v-model="item.organization" placeholder="试验类型" class=" max-width" type="text">
                    </div>
                  </template>
                  <v-list class="pa-0 radius-0" subheader>
                    <v-list-item>
                      <v-list-item-title>dsf</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

            </v-data-table>

          </div>

        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div class="max-width flex-y-center flex-justify-between">

            <div class="flex-1 flex-justify-end">
              <v-btn width="80" height="30" class depressed @click="activation.show = false">取消</v-btn>
              <v-btn
                width="80"
                class="ml-3"
                height="30"
                color="primary"
                depressed
                @click.stop="activation.show = false"
              >确定</v-btn>

            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 批量激活 end -->

    <!-- 关联项目 start -->
    <v-dialog v-model="projectDialog.show" width="800">
      <v-card>
        <v-card-title class="font-weight-bold">关联项目</v-card-title>
        <v-divider />
        <v-card-text class=" pb-5 px-0">
          <div class="flex-1 flex-column overflow-auto">
            <v-data-table
              :headers="projectDialog.headers"
              :items="projectDialog.list"
              :items-per-page="search.pageSize"
              class="pb-5 max-width header-grey "
              hide-default-footer
              item-key="id"
              no-data-text="暂无查询数据"
              disable-sort
              :loading="projectDialog.listLoading"
            >
              <template v-slot:item.rowIndex="{index}">
                {{ (index+1)+(projectDialog.search.pageSize*(projectDialog.search.pageNo-1)) }}
              </template>
            </v-data-table>
            <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end">
              <pagination v-model="projectDialog.search.pageNo" :size="projectDialog.search.pageSize" :total="projectDialog.total" @input="userGetRelatedProjects" @changePageSize="projectChangePageSize" />
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div class="max-width flex-y-center flex-justify-between">
            <div class="flex-1 flex-justify-end">
              <v-btn width="80" height="30" class depressed @click="projectDialog.show = false">关闭</v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 关联项目 end -->

    <!-- 设置密码 start -->
    <v-dialog v-model="passwordDialog.show" width="500">
      <v-card>
        <form class="max-height flex-column overflow-hidden" @submit.prevent="passwordSubmit">
          <v-card-title class="font-weight-bold">设置密码</v-card-title>
          <v-divider />
          <v-card-text class="flex-center px-12 body-1">
            <div class="mt-6 max-width">
              <div class="flex-y-center mb-8">
                <span
                  class="flex-0 flex-shrink-0 flex-justify-end mr-2 body-1"
                  style="width:86px;"
                ><span class="red--text ml-1">*</span>设置密码：</span>
                <form-item
                  v-model="passwordDialog.data.password"
                  class="mr-0"
                  required
                  placeholder="设置密码"
                  type="password"
                />
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <v-btn width="80" class="mr-2" v depressed @click="passwordDialog.show = false,passwordDialog.data={}">关闭</v-btn>
                <v-btn type="submit" height="30" :loading="passwordDialog.btnLoading" color="primary" width="80" class depressed>确认</v-btn>
              </div>
            </div>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <!-- 设置密码 end -->

    <!-- 新增 编辑 用户 start -->
    <v-navigation-drawer
      v-model="drawer.show"
      temporary
      app
      right
      width="700"
    >
      <form v-if="drawer.show" class="max-height flex-column overflow-hidden" @submit.prevent="submit()">
        <div class=" flex-0 flex-justify-between flex-y-center py-3 px-5" style="border-bottom:1px solid #e1e1e1">
          <span class="text-h6">{{ drawer.data.id?'编辑用户':'新增用户' }}</span>
          <v-icon @click="drawer.show=false,drawer.data={},errorEmail=false,errorPhone=false">mdi-close</v-icon>
        </div>
        <div class="flex-1 flex-column overflow-auto mt-7 mx-12 px-12">
          <!-- 新增外部 -->
          <template v-if="!drawer.data.id&&tab==2">
            <div class="mb-6 flex-align-start mt-2">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>从通讯录中选取：</div>
              <v-radio-group
                v-model="drawer.status"
                row
                dense
                class="mt-0"
                @change="drawer.data.password='',drawer.data.username='',drawer.data.partnerId='',drawer.data.realname='',drawer.data.userId='',drawer.data.sex='',drawer.data.email='',errorEmail=false,drawer.data.phone='',errorPhone=false,
                         $forceUpdate()"
              >
                <v-radio label="是" value="1" />
                <v-radio label="否" value="0" />
              </v-radio-group>
            </div>
            <template v-if="drawer.status=='1'">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>组织单位：</div>
                <form-item
                  v-model="drawer.data.partnerId"
                  class=" flex-1 flex-shrink-0"
                  :options="partnerList"
                  required
                  placeholder="组织单位"
                  dense
                  type="select"
                  @input="partnerSelect"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>姓名：</div>
                <form-item
                  v-model="drawer.data.userId"
                  class=" flex-1 flex-shrink-0"
                  :options="addressBookList"
                  placeholder="姓名"
                  required
                  dense
                  type="select"
                  @input="bookSelect"
                />
                <!-- v-model="drawer.data.realname" -->
              </div>
            </template>
            <template v-else>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">组织单位：</div>
                <form-item
                  v-model="drawer.data.partnerId"
                  class=" flex-1 flex-shrink-0"
                  :options="partnerList"
                  placeholder="组织单位"
                  dense
                  type="select"
                />
              </div>
              <div class="mb-6 flex-align-start">

                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>姓名：</div>
                <form-item
                  v-model="drawer.data.realname"
                  class="mr-0"
                  placeholder="姓名"
                  required
                  type="text"
                />
                <!-- <input class="flex-1 default-input py-1 subtitle-1" placeholder="姓名" required type="text"
                              v-model="drawer.data.realname"/> -->
              </div>
            </template>

            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>用户账号：</div>
              <form-item
                v-model="drawer.data.username"
                class="mr-0"
                placeholder="用户账号"
                required
                type="text"
              />
              <!-- <input class="flex-1 default-input py-1 subtitle-1" placeholder="用户账号" required type="text"
                              v-model="drawer.data.username"/> -->

            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">性别：</div>
              <form-item
                v-model="drawer.data.sex"
                class=" flex-1 flex-shrink-0"
                :disabled="drawer.status==='1'"
                :options="[{text:'男',value:1},{text:'女',value:2}]"
                placeholder="组织单位"
                dense
                type="radio"
              />
              <!-- <v-radio-group :readonly="drawer.status==1" v-model="drawer.data.sex" row dense class="mt-0">
                          <v-radio label="男" :value="1"></v-radio>
                          <v-radio label="女" :value="2"></v-radio>
                      </v-radio-group> -->
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>邮箱：</div>
              <!-- <form-item :disabled="drawer.status==1" class="mr-0" required placeholder="邮箱" type="text"
                          v-model="drawer.data.email"></form-item> -->
              <div class=" flex-1 flex-column">
                <form-item
                  v-model="drawer.data.email"
                  :disabled="drawer.status==='1'"
                  class=" flex-1 mr-0"
                  required
                  placeholder="邮箱"
                  type="text"
                  @changeInput="emailTest"
                />
                <span v-if="errorEmail" class="pt-1 error--text">邮箱格式错误</span>
              </div>
              <!-- <input :disabled="drawer.status==1" class="flex-1 default-input py-1 subtitle-1" placeholder="邮箱" type="text"
                            v-model="drawer.data.email"/> -->
            </div>

            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">联系电话：</div>
              <!-- <form-item :disabled="drawer.status==1" class="mr-0" placeholder="联系电话" type="text"
                          v-model="drawer.data.phone"></form-item> -->
              <div class=" flex-1 flex-column">
                <form-item
                  v-model="drawer.data.phone"
                  :disabled="drawer.status==='1'"
                  class=" flex-1 mr-0"
                  placeholder="联系电话"
                  type="text"
                  @changeInput="phoneTest"
                />
                <span v-if="errorPhone" class="pt-1 error--text">联系电话格式错误</span>
              </div>
              <!-- <input :disabled="drawer.status==1" class="flex-1 default-input py-1 subtitle-1" placeholder="联系电话" required type="text"
                            v-model="drawer.data.phone"/> -->
            </div>

          </template>

          <template v-else>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>用户账号：</div>
              <!-- :disabled="tab==2&&(parseInt(drawer.partnerId)>0)" -->
              <form-item
                v-model="drawer.data.username"
                :disabled="((tab==2)||drawer.data.id)?true:false"
                class="mr-0"
                required
                placeholder="用户账号"
                type="text"
              />
              <!-- <input class="flex-1 default-input py-1 subtitle-1" :readonly="tab==2&&(parseInt(drawer.partnerId)>0)" placeholder="用户账号" required type="text"
                            v-model="drawer.data.username"/> -->
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>姓名：</div>
              <form-item
                v-model="drawer.data.realname"
                :disabled="tab==2&&(parseInt(drawer.partnerId)>0)"
                class="mr-0"
                required
                placeholder="姓名"
                type="text"
              />
              <!-- <input class="flex-1 default-input py-1 subtitle-1" :readonly="tab==2&&(parseInt(drawer.partnerId)>0)" placeholder="姓名" required type="text"
                            v-model="drawer.data.realname"/> -->
            </div>
            <template v-if="tab==1">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>组织角色：</div>
                <v-menu
                  :close-on-content-click="false"
                  bottom
                  max-height="300"
                  offset-overflow
                  transition="slide-y-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <div class=" default-input flex-y-center py-1" color="primary" style="height:34px;background:#fdfdfd;position: relative;" v-on="on">
                      <span v-if="drawer.selectedroles&&drawer.selectedroles.length" v-text="backRoleName()" />
                      <span v-else class=" grey--text text--lighten-1">组织角色</span>
                      <input :value="backRoleName()" style="position: absolute;opacity: 0; width:1px;" class=" flex-1" required>
                      <!-- <input placeholder="角色" required readonly class=" max-width" type="text"  /> -->
                    </div>
                    <!-- :value="(drawer.departIds.length&&drawer.departIds[0].departName)||''" -->
                  </template>
                  <v-card class=" elevation-0">
                    <!-- selectedroles -->
                    <v-list class="pa-0 radius-0" subheader>
                      <template v-for="(item,index) in roleList">
                        <v-list-item v-if="!((item.roleName=='超级管理员') || ((userMsg.username != 'administrator') && (item.roleName=='管理员')))" :key="index">
                          <v-checkbox
                            v-model="drawer.selectedroles"
                            class="ml-2 py-0"
                            style="height:32px;margin-top:10px;"
                            :value="item.id"
                            :label="item.roleName"
                          />
                          <!-- <v-list-item-title>{{item.name}}</v-list-item-title> -->
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span v-if="!chooseAdmin" class="red--text ml-1">*</span>部门：</div>
                <v-menu
                  v-if="!chooseAdmin"
                  :close-on-content-click="false"
                  bottom
                  max-height="300"
                  offset-overflow
                  transition="slide-y-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <div class=" default-input flex-y-center py-1" color="primary" style="height:34px;background:#fdfdfd;position: relative;" v-on="on">
                      <span v-if="drawer.selecteddeparts&&drawer.selecteddeparts.length" v-text="backDepartName()" />
                      <span v-else class=" grey--text text--lighten-1">部门</span>
                      <input :value="backDepartName()" style="position: absolute;opacity: 0; width:1px;" class=" flex-1" required>
                      <!-- <input placeholder="部门" required readonly class=" max-width" type="text" /> -->
                      <!-- :value="(drawer.departIds.length&&drawer.departIds[0].departName)||''" -->
                    </div>
                    <!-- selecteddeparts -->
                  </template>
                  <v-card class=" elevation-0">
                    <!-- :active.sync="drawer.departIds" return-object-->
                    <v-treeview
                      v-model="drawer.selecteddeparts"
                      activatable
                      :items="trees"
                      item-text="departName"
                      selected-color="primary"
                      selection-type="independent"
                      selectable
                    />
                  </v-card>
                  <!-- <v-list class="pa-0 radius-0" subheader>
                                <v-list-item v-for="(item,index) in partnerList" :key="index">
                                    <v-list-item-title>{{item.name}}</v-list-item-title>
                                </v-list-item>
                            </v-list> -->
                </v-menu>
                <form-item
                  v-else
                  v-model="drawer.selecteddeparts"
                  class=" flex-1 flex-shrink-0"
                  disabled
                  placeholder="部门"
                  dense
                  type="text"
                />

                <!-- <input class="flex-1 default-input py-1 subtitle-1" placeholder="部门" required type="text"
                              v-model="drawer.data.departIds"/> -->
              </div>
            </template>
            <div v-else class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>组织单位：</div>

              <form-item
                v-model="drawer.data.partnerId"
                class=" flex-1 flex-shrink-0"
                :disabled="tab==2&&(parseInt(drawer.partnerId)>0)"
                :options="partnerList"
                placeholder="组织单位"
                dense
                type="select"
              />
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span v-if="!chooseAdmin" class="red--text ml-1">*</span>性别：</div>
              <form-item
                v-model="drawer.data.sex"
                class=" flex-1 flex-shrink-0"
                :disabled="tab==2&&(parseInt(drawer.partnerId)>0)"
                :options="[{text:'男',value:1},{text:'女',value:2}]"
                placeholder="组织单位"
                :required="chooseAdmin?false:true"
                :allow-close="chooseAdmin?true:false"
                dense
                type="radio"
              />
              <!-- <v-radio-group v-model="drawer.data.sex"  :readonly="tab==2&&(parseInt(drawer.partnerId)>0)" row dense class="mt-0">
                          <v-radio label="男" :value="1"></v-radio>
                          <v-radio label="女" :value="2"></v-radio>
                      </v-radio-group> -->
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span v-if="!chooseAdmin" class="red--text ml-1">*</span>邮箱：</div>
              <!-- <form-item :disabled="tab==2&&(parseInt(drawer.partnerId)>0)" class="mr-0" required placeholder="邮箱" type="text"
                          v-model="drawer.data.email"></form-item> -->
              <div class=" flex-1 flex-column">
                <form-item
                  v-model="drawer.data.email"
                  :disabled="tab==2&&(parseInt(drawer.partnerId)>0)"
                  class=" flex-1 mr-0"
                  :required="chooseAdmin?false:true"
                  placeholder="邮箱"
                  type="text"
                  @changeInput="emailTest"
                />
                <span v-if="errorEmail" class="pt-1 error--text">邮箱格式错误</span>
              </div>

              <!-- <input class="flex-1 default-input py-1 subtitle-1"  :readonly="tab==2&&(parseInt(drawer.partnerId)>0)" placeholder="邮箱" type="text"
                            v-model="drawer.data.email"/> -->
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span v-if="!chooseAdmin" class="red--text ml-1">*</span>联系电话：</div>
              <!-- <form-item :disabled="tab==2&&(parseInt(drawer.partnerId)>0)" class="mr-0" required placeholder="联系电话" type="text"
                          v-model="drawer.data.phone"></form-item> -->
              <div class=" flex-1 flex-column">
                <form-item
                  v-model="drawer.data.phone"
                  :disabled="tab==2&&(parseInt(drawer.partnerId)>0)"
                  class=" flex-1 mr-0"
                  :required="chooseAdmin?false:true"
                  placeholder="联系电话"
                  type="text"
                  @changeInput="phoneTest"
                />
                <span v-if="errorPhone" class="pt-1 error--text">联系电话格式错误</span>
              </div>
              <!-- <input class="flex-1 default-input py-1 subtitle-1"  :readonly="tab==2&&(parseInt(drawer.partnerId)>0)" placeholder="联系电话" required type="text"
                            v-model="drawer.data.phone"/> -->
            </div>
            <template v-if="tab==1">
              <!-- <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>设为主管：</div>
                <form-item
                  v-model="drawer.data.managerFlag"
                  class=" flex-1 flex-shrink-0"
                  :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                  placeholder="设为主管"
                  required
                  dense
                  type="select"
                />
              </div> -->
              <!-- <div class="mb-6 flex-align-start">
                    <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>组织角色：</div>
                    <v-menu
                      :close-on-content-click="false"
                      bottom
                      max-height="300"
                      offset-overflow
                      transition="slide-y-transition"
                      offset-y
                      >
                        <template v-slot:activator="{ on }">
                            <div class="py-1" color="primary" v-on="on">
                                <input placeholder="组织角色" class=" max-width" type="text" v-model="search.data.type" />
                            </div>
                        </template>
                        <v-list class="pa-0 radius-0" subheader>
                            <v-list-item >
                                <v-list-item-title>内部用户</v-list-item-title>
                            </v-list-item>
                            <v-list-item  >
                                <v-list-item-title>外部用户</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                  </div> -->
            </template>
          </template>
          <template v-if="!(tab==1&&(drawer.data.username == 'admin'||drawer.data.username == 'administrator'))">
            <div class="mb-1 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">过期日期：</div>
              <form-item
                v-model="drawer.data.expirationTime"
                class="mr-0"
                placeholder="过期日期"
                allow-close
                type="date"
              />
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" />
              <span class=" error--text mt-2">过期日期设置为空，该用户为长期使用！</span>
            </div>
          </template>

        </div>
        <div class=" flex-0 flex-shrink-0 py-3 px-5 flex-justify-end" style="border-top:1px solid #e1e1e1;">
          <v-btn width="100" class="mr-2" height="30" depressed @click="drawer.show = false,drawer.data={},errorEmail=false,errorPhone=false">取消</v-btn>
          <!-- <v-btn type="submit" height="30" :loading="btnLoading" color="primary" width="100" depressed">提交</v-btn> -->
          <div style=" position:relative;">
            <input ref="form" style=" position:absolute;" type="submit" value="submit">
            <v-btn height="30" :loading="btnLoading" color="primary" width="100" depressed @click.stop="subSave">提交</v-btn>
          </div>
        </div>
      </form>
    </v-navigation-drawer>
    <!-- 新增 编辑 用户 end -->
    <v-dialog
      v-model="alert.show"
      width="300"
      persistent
    >
      <v-card>
        <v-card-title class="">
          提示
        </v-card-title>
        <v-card-text class="mt-8 body-1">
          {{ alert.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="primary"
            class=" body-1"
            @click="sureAlert"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      userMsg: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
      tab: '1',
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0,
      trees: [],
      roleList: [],
      listLoading: false,
      headers: [
        { text: '序号', value: 'rowIndex', width: '60', sortable: false },
        { text: '用户账号', value: 'username' },
        { text: '姓名', value: 'realname' },
        { text: '性别', value: 'sex' },
        { text: '邮箱', value: 'email' },
        { text: '联系电话', value: 'phone' },
        // { text: "组织单位", value: "orgCodeTxt" },
        { text: '组织单位', value: 'roleName' },
        { text: '部门', value: 'orgCodeTxt' },
        { text: '过期日期', value: 'expirationTime' },
        { text: '状态', value: 'status' },
        { text: '操作', value: 'active', sortable: false }
      ],
      list: [],
      showMenuDepart: false,
      // 下拉单选: 1-可用 2-禁用 3-锁定 4-过期
      statusOptions: [
        { text: '可用', value: 1 },
        { text: '禁用', value: 2 },
        { text: '锁定', value: 3 },
        { text: '过期', value: 4 }
      ],
      statusExternalOptions: [
        { text: '可用', value: 1 },
        { text: '禁用', value: 2 },
        { text: '锁定', value: 3 }
      ],
      activation: { // 用户项目关联
        show: false,
        search: {
          pageSize: 10,
          pageNo: 1,
          data: {}
        },
        total: 0,
        headers: [
          { text: '序号', value: 'rowIndex' },
          { text: '姓名', value: 'username' },
          { text: '邮箱', value: 'birthday' },
          { text: '用户名', value: 'realname' },
          { text: '组织角色', value: 'organization' }
        ],
        list: []
      },
      btnLoading: false,
      drawer: { // 新增 编辑 用户 侧边框
        show: false,
        data: {},
        departIds: [],
        selectedroles: [],
        selecteddeparts: []
      },
      projectDialog: { // 项目关联 弹窗
        show: false,
        search: {
          pageSize: 10,
          pageNo: 1,
          data: {}
        },
        listLoading: false,
        total: 0,
        headers: [
          { text: '序号', value: 'rowIndex', with: '50' },
          { text: '项目编号', value: 'projectNumber', with: '100' },
          { text: '方案编号', value: 'projectProtocolNumber', with: '100' },
          { text: '项目名称', value: 'projectName', with: '200' }
        ],
        list: []
      },
      partnerList: [], // 合作单位
      addressBookList: [], // 合作单位-组织单位通讯录
      passwordDialog: { // 设置密码 弹窗
        show: false,
        btnLoading: false,
        data: {}
      },
      errorEmail: false,
      errorPhone: false,
      chooseAdmin: false, // 是否选择了admin角色
      alert: {
        show: false,
        text: ''
      }
    }
  },
  watch: {
    tab: function(newval) {
      if (newval === '1') {
        this.headers = [
          { text: '序号', value: 'rowIndex', width: '60', sortable: false },
          { text: '用户账号', value: 'username' },
          { text: '用户姓名', value: 'realname' },
          { text: '性别', value: 'sex' },
          { text: '邮箱', value: 'email' },
          { text: '联系电话', value: 'phone' },
          { text: '组织角色', value: 'roleName' },
          { text: '部门', value: 'orgCodeTxt' },
          { text: '过期日期', value: 'expirationTime' },
          { text: '状态', value: 'status' },
          { text: '操作', value: 'active', sortable: false }
        ]
      } else if (newval === '2') {
        this.headers = [
          { text: '序号', value: 'rowIndex', width: '60', sortable: false },
          { text: '用户账号', value: 'username' },
          { text: '用户姓名', value: 'realname' },
          { text: '性别', value: 'sex' },
          { text: '邮箱', value: 'email' },
          { text: '联系电话', value: 'phone' },
          { text: '组织角色', value: 'roleName' },
          { text: '过期日期', value: 'expirationTime' },
          { text: '状态', value: 'status' },
          { text: '操作', value: 'active', sortable: false }
        ]
      }
    },
    'drawer.selectedroles': {
      handler(val) {
        let chooseAdmin = false
        let _f = false
        if (val) {
          val.forEach(item => {
            if (!_f) {
              if (this.roleList.find(_t => item === _t.id).roleName === '管理员') {
                _f = true
                chooseAdmin = true
                this.drawer.selecteddeparts = []
                // this.errorEmail = false
                // this.errorPhone = false
              }
            }
          })
        }
        this.chooseAdmin = chooseAdmin
      },
      immediate: true,
      deep: true
    }
    // drawer.selectedroles"roleList roleName管理员
  },
  mounted() {
    this.initList()
    this.getPartnerList()
    this.getQueryDepartTreeSync()
    this.getRoleQueryall()
  },
  methods: {
    initList() {
      this.search.pageNo = 1
      this.total = 0
      this.getUserList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.getUserList()
    },
    // 获取用户列表
    getUserList() {
      this.listLoading = true
      const _data = { ...this.search.data }
      _data.userCategory = this.tab
      _data.pageSize = this.search.pageSize
      _data.pageNo = this.search.pageNo
      const param = new URLSearchParams()

      for (const i in _data) {
        if (_data[i]) param.append(i, _data[i])
      }
      this.$http.get(this.$api.userList, { data: param }).then(res => {
        this.listLoading = false
        this.list = (res.result && res.result.records) || []
        this.total = (res.result && res.result.total) || 0
      }).catch(() => {
        this.listLoading = false
      })
    },
    // 部门 树
    getQueryDepartTreeSync() {
      // queryDepartTreeSync sysDepartQueryTreeList
      this.$http.get(this.$api.sysDepartQueryTreeList, {}).then(res => {
        this.trees = res.result || []
      })
    },
    // 角色
    getRoleQueryall() {
      this.$http.get(this.$api.roleQueryAll, { data: { roleCategory: '0' }}).then(res => {
        this.roleList = res.result || []
      })
    },
    // 获取合作单位-组织单位列表
    getPartnerList() {
      this.$http.get(this.$api.partnerList, {}).then(res => {
        if (res.result && res.result.length) {
          res.result.forEach(item => {
            item.text = item.partnerName
            item.value = item.id
          })
        }
        this.partnerList = res.result || []
      })
    },
    // 合作单位-组织单位通讯录
    partnerAddressBook(id) {
      this.$http.get(this.$api.partnerAddressBook, { data: { id: id }}).then(res => {
        if (res.result && res.result.length) {
          res.result.forEach(item => {
            item.text = item.name
            // item.value=item.name
            item.value = item.id
          })
        }
        this.addressBookList = res.result || []
      })
    },

    // 通过id获取用户详情
    // getUserQueryById(id){
    //   this.$http.get(this.$api.userQueryById,{data:{id:id}}).then(res => {
    //           console.log(res)

    //   });
    // },
    // 用户-查询用户角色
    userQueryUserRole(id) {
      this.$http.get(this.$api.userQueryUserRole, { data: { userid: id }}).then(res => {
        console.log(res.result)
        if (res.result && res.result.length) {
          this.drawer.selectedroles = res.result
        }
        // this.drawer.data.userName=this.drawer.data.username
        // selecteddeparts //部门   selectedroles//角色
      })
    },
    // 用户-查询用户部门
    userUserDepartList(id) {
      this.$http.get(this.$api.userUserDepartList, { data: { userId: id }}).then(res => {
        let _arr = []
        if (res.result && res.result.length) {
          // this.drawer.selecteddeparts = res.result
          _arr = res.result.map(_t => { return _t.key })
        }
        this.drawer.selecteddeparts = _arr
        // this.drawer.data.userName=this.drawer.data.username
        // selecteddeparts //部门   selectedroles//角色
      })
    },

    // 切换内部用户外部用户
    tabChange() {
      this.search.departName = ''
      this.search.data = {}
      this.initList()
      // this.getUserList()
    },
    // 关联项目 改变页数
    projectChangePageSize(e) {
      this.projectDialog.search.pageSize = e
      this.projectDialog.search.pageNo = 1
      this.$forceUpdate()
      this.userGetRelatedProjects()
    },
    // 关联项目 获取列表
    userGetRelatedProjects() {
      const param = new URLSearchParams()
      param.append('userId', this.projectDialog.userId)
      param.append('pageSize', this.projectDialog.search.pageSize)
      param.append('pageNo', this.projectDialog.search.pageNo)
      this.projectDialog.listLoading = true
      this.$http.get(this.$api.userGetRelatedProjects, { data: param }).then(res => {
        this.projectDialog.listLoading = false
        this.projectDialog.list = (res.result && res.result.records) || []
        this.projectDialog.total = (res.result && res.result.total) || 0
      }).catch(() => {
        this.projectDialog.list = []
        this.projectDialog.total = 0
        this.projectDialog.listLoading = false
      })
    },
    // 部门 筛选
    searchDepart(item) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.search.data.departIds = item.id
          this.search.departName = item.departName
          this.showMenuDepart = false
          this.$forceUpdate()
          this.initList()
        }, 100)
      })
    },
    // 确认 删除后恢复
    userRecovery(id) {
      this.$confirm({ text: '是否确认恢复？' }).then((vm) => {
        const param = new URLSearchParams()
        param.append('id', id)
        vm.loading = true
        this.$http.post(this.$api.userRecovery, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          this.getUserList()
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    // 点击提交
    subSave() {
      const u = navigator.userAgent
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.focus()
      this.$nextTick(() => {
        document.body.removeChild(input) // 获取焦点移除节点
        if (u.indexOf('Firefox') > -1) {
          setTimeout(() => {
            this.$refs.form.click()
          }, 300)
        } else {
          this.$refs.form.click()
        }
      })
    },
    // 解锁 锁定用户
    userFrozenBatch(id, type) {
      // if(confirm('是否确认'+(type==1?'解锁':'锁定')+'？')){
      // if(confirm('是否确认'+(type==1?'恢复':'冻结')+'？')){
      //   this.$http.put(this.$api.userFrozenBatch,{data:{id:id,status:type}}).then(res => {
      //       this.$message.success(res.message)
      //       this.getUserList()
      //   });
      // }
      this.$confirm({ text: '是否确认' + (type === '1' ? '恢复' : '禁用') + '？' }).then((vm) => {
        vm.loading = true
        this.$http.put(this.$api.userFrozenBatch, { data: { id: id, status: type }}).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          this.getUserList()
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    // 删除用户
    showDel(id) {
      // if(confirm('是否确认删除？')){
      //   this.$http.delete(this.$api.userDelete,{data:{id:id}}).then(res => {
      //       this.$message.success(res.message)
      //       this.getUserList()
      //   });
      // }
      this.$confirm({ text: '是否确认删除？' }).then((vm) => {
        const param = new URLSearchParams()
        param.append('id', id || '')
        vm.loading = true
        this.$http.delete(this.$api.userDelete, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          // this.getUserList()
          this.initList()
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },

    // 编辑用户 弹窗
    showEdit(item) {
      this.chooseAdmin = false
      this.errorEmail = false
      this.errorPhone = false
      this.drawer.show = true
      this.drawer.data = { ...item }
      this.drawer.partnerId = item.partnerId || '0'

      this.drawer.selectedroles = []
      this.drawer.selecteddeparts = []
      if (this.tab === '1') {
        this.userQueryUserRole(item.id)
        this.userUserDepartList(item.id)
      }

      // if(this.drawer.data.selectedroles){
      //   this.drawer.selectedroles=this.drawer.data.selectedroles.split(',')||[]
      // }
      // if(this.drawer.data.selecteddeparts){
      //   this.drawer.selecteddeparts=this.drawer.data.selecteddeparts.split(',')||[]
      // }

      // this.getUserQueryById(item.id)

      // this.drawer.data.userName=this.drawer.data.username
      // selecteddeparts //部门   selectedroles//角色
      // let departIds=[]
      // this.drawer.departIds=[]
      // let _f=false
      // let _h=(arr)=>{
      //   arr.forEach(_item=>{
      //     if(!_f){
      //       if(_item.id==item.departIds){
      //         _f=true
      //         departIds.push(_item)
      //       }else{
      //         if(_item.children&&_item.children.length) _h(_item.children)
      //       }
      //     }
      //   })

      // }
      // if(item.departIds){
      //   _h(this.trees)
      //   this.drawer.departIds=departIds
      //   this.$forceUpdate()
      // }
    },
    // 是否缺重置密码
    resetUserPassword(id) {
      this.$confirm({ text: '是否确认重置密码？' }).then((vm) => {
        const param = new URLSearchParams()
        param.append('userId', id)
        vm.loading = true
        this.$http.post(this.$api.resetUserPassword, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          this.alert.text = res.result
          this.alert.show = true
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    sureAlert() {
      this.$confirm({ text: '请确定已记住密码，关闭后将无法找回！', okText: '确认关闭' }).then((vm) => {
        vm.show = false
        this.alert.show = false
      }).catch(() => {})
    },
    // 用户 确认编辑 确认添加
    submit() {
      if (this.errorEmail || this.errorPhone) return
      const _data = { ...this.drawer.data }
      let url = this.$api.userAdd
      let method = 'post'
      if (this.drawer.data.id) {
        url = this.$api.userEdit
        method = 'put'
      }
      // _data.selectedroles=_data.selectedroles.join(',')
      // _data.selecteddeparts=_data.selecteddeparts.join(',')

      if (this.tab === '1') {
        _data.selectedroles = this.drawer.selectedroles.join(',') || ''
        _data.selecteddeparts = this.drawer.selecteddeparts.join(',') || ''
        if (!this.drawer.data.id) _data.partnerId = 0
      } else {
        // if(!this.drawer.data.id) _data.partnerId=_data.userId
        if (!this.drawer.data.id) { // 新增的外部用户
          if (this.drawer.status === '1') { // 来自通讯录
            // _data.fromPartner=1
            _data.addressBookId = _data.userId
            // _data.partnerId=_data.userId
          } else if (this.drawer.status === '0') { // 不来自通讯录
            _data.addressBookId = 0
            // _data.fromPartner=2
            // partnerId和 addressBookId
          }
        }
      }
      // userId
      _data.userCategory = this.tab
      // _data.departIds=this.drawer.departIds[0]||''
      _data.departIds = (this.drawer.departIds.length && this.drawer.departIds[0].id) || ''

      this.btnLoading = true
      this.$http[method](url, { data: _data }).then(res => {
        // this.$message.success(res.message)
        if (this.chooseAdmin && !this.drawer.data.id) {
          this.alert.text = res.message
          this.alert.show = true
        } else {
          this.$message.success(res.message)
        }
        this.drawer.show = false
        this.btnLoading = false
        // this.drawer.data={selecteddeparts:[],selectedroles:[]}
        this.drawer.data = {}
        this.errorEmail = false
        this.errorPhone = false
        this.drawer.selecteddeparts = []
        this.drawer.selectedroles = []
        this.getUserList()
      }).catch(() => {
        this.btnLoading = false
      })
    },

    // 确认编辑用户密码
    passwordSubmit() {
      this.passwordDialog.btnLoading = true
      const param = new URLSearchParams()
      param.append('password', this.passwordDialog.data.password)
      param.append('userId', this.passwordDialog.data.userId)
      this.$http.post(this.$api.userChangePassword, { data: param }).then(res => {
        this.$message.success(res.message)
        this.passwordDialog.show = false
        this.passwordDialog.btnLoading = false
        this.passwordDialog.data = {}
      }).catch(() => {
        this.passwordDialog.btnLoading = false
      })
    },

    // 返回角色名称
    backRoleName() {
      const _arr = []
      if (this.roleList && this.roleList.length) {
        this.roleList.forEach(item => {
          if (this.drawer.selectedroles.includes(item.id) && (_arr.indexOf(item.roleName) === -1)) {
            _arr.push(item.roleName)
          }
        })
      }
      return _arr.join(',')
    },
    // 返回部门名称
    backDepartName() {
      // this.drawer.data.selecteddeparts drawer.selecteddeparts
      const _arr = []
      const ids = this.drawer.selecteddeparts || []
      const _h = (arr) => {
        arr.forEach(item => {
          if (ids.includes(item.id) && (_arr.indexOf(item.departName) === -1)) {
            _arr.push(item.departName)
          }
          if (item.children && item.children.length) _h(item.children)
        })
      }
      if (this.trees && this.trees.length && ids && ids.length) _h(this.trees)
      return _arr.join(',')
    },

    // 添加外部用户 弹窗 选择外部通讯录
    partnerSelect() {
      // this.drawer.data.realname=''
      this.$set(this.drawer.data, 'userId', '')
      this.$set(this.drawer.data, 'realname', '')
      this.$set(this.drawer.data, 'sex', '')
      this.$set(this.drawer.data, 'email', '')
      this.$set(this.drawer.data, 'phone', '')
      this.addressBookList = []
      this.$forceUpdate()
      if (this.drawer.data.partnerId) this.partnerAddressBook(this.drawer.data.partnerId)
    },
    emailTest(e) {
      if (e) {
        const _c = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/
        if (!_c.test(e)) {
          this.errorEmail = true
        } else {
          this.errorEmail = false
        }
      } else {
        this.errorEmail = false
      }
    },
    phoneTest(e) {
      // let _c=/^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (e) {
        const _c = /^[1][0-9][0-9]{9}$/
        if (!_c.test(e)) {
          this.errorPhone = true
        } else {
          this.errorPhone = false
        }
      } else {
        this.errorPhone = false
      }
    },
    // 返回姓名
    bookSelect() {
      this.$set(this.drawer.data, 'sex', '')
      this.$set(this.drawer.data, 'email', '')
      this.$set(this.drawer.data, 'phone', '')
      this.errorEmail = false
      this.errorPhone = false
      this.addressBookList.forEach(item => {
        // if(item.name==this.drawer.data.realname){
        if (item.id === this.drawer.data.userId) {
          // this.drawer.data.sex=parseInt(item.gender)
          // this.drawer.data.email=item.email
          // this.drawer.data.phone=item.phone
          this.$set(this.drawer.data, 'realname', item.name)
          this.$set(this.drawer.data, 'sex', parseInt(item.gender))
          this.$set(this.drawer.data, 'email', item.email)
          this.$set(this.drawer.data, 'phone', item.phone)
        }
      })
    }
    // drawer.data.sex drawer.data.email drawer.data.phone

  }
}
</script>

<style lang="scss" scoped>
.form-title{
    width:130px;
    color: #777;
}
</style>
